<template>
	<div :span="6">
		<div v-if="name !== 'Get more apps from our Marketplace'" class="image-holder-box" @mouseenter="mousingover" @mouseleave="hovering = false">
			<div :style="[hovering? {'height' : '150px'} : {'':''}]" style="height: 198px; width: 100%; padding: 15px;">
				<a :href="link">
					<!-- App icon -->
					<div :style="[hovering? {'height' : '90px'} : {'':''}]" style="width: 100%; height: 120px;" class="dF jC aC">
						<img :src="img" :style="[hovering? {'height' : '70px', 'width' : '70px'} : {'':''}]" style="max-width:80px; max-height:80px;" />
					</div>

					<!-- App name -->
					<div :class="hovering? 'reducediv' : ''" style="width: 100%; height: 50px; display: block;">
						<h6>
							{{name}}
						</h6>
					</div>
				</a>
			</div>
			<div v-show="hovering" style="height: 30px;">
				<hr style="margin-bottom: 8px; margin-left: 15px; margin-right: 15px;"/>
				<div class="w-full dF" style="margin-left: 0; margin-right: 0; padding: 0 10px;">
					<div class="jS">
						<!-- <div style="font-size: 12px; float: left; color: #66788A;"><a-icon type="clock-circle" style="margin-right: 5px;" />{{getDate(date)}}</div> -->
					</div>
					<div class="jE" style="flex: 1;">
						<!-- TRASH ICON -->
						<div v-if="category !== 'default'" class="edit-delete" @click="openSettings(id)" style="font-size: 12px; float: right;">
							<a-icon type="setting" />
						</div>
						<div v-if="category !== 'default' && false" class="edit-delete" @click="deleteApp(id)" style="font-size: 12px; float: right;">
							<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="image-holder-box-2" @click="$emit('marketplace')">
			<!-- <a href=""> -->
				<div style="margin-top: 30px;">
					<!-- App icon -->
					<img src="@/assets/marketplace-icon.svg" class="mb-3" style="width:90px; height:90px; object-fit: contain;" />
					<!-- App name -->
					<h6>
						{{name}}
					</h6>
				</div>
			<!-- </a> -->
		</div>
	</div>
</template>

<script>
export default {
	props: {
		img:{
			default:'',
			type:String
		},
		name:{
			default:'',
			type:String
		},
		link:{
			default:'',
			type:String
		},
		date:{
			default:'',
			type:String
		},
		id:{
			default:'',
			type:String
		},
		category:{
			default:'',
			type:String
		}
    },
    data(){
        return {
            hovering:false,
        }
    },
    methods:{
		openSettings(id) {
			let apps = this.instance.apps || []
			let chosenApp = {}
			chosenApp = apps.find(x => x.id == id)
			this.$store.commit("OPEN_MARKETPLACE", chosenApp)
		},
		mousingover(){
			if (this.category !== 'default'){
				this.hovering = true
			}
		},
       	getDate(data){
			var date = data
			const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			let year = date.substring(0,4)
			let month = date.substring(5,7)
			if (month.substring(0,1) == '0'){
				month = date.substring(6,7)
			}
			month = Number(month)
			let day = date.substring(8,10)
			if (day.substring(0,1) == '0'){
				day = date.substring(9,10)
			}
			return monthNames[month-1] + ' ' + day + ', ' + year
		},
		deleteApp(data){
			console.log(data)
		},
    },
    computed:{
        instance(){
			return this.$store.state.instance
		}
    },
}
</script>

<style lang="scss">

</style>

<style scoped>
	.image-holder-box{
		display: table;
		width: 100%;
		height: 200px;
		text-align: center;
		vertical-align: top;
		border: 1px solid #d8d7d7;
		border-color: transparent;
		background-color: var(--white);
		box-shadow: 0px 1px 3px rgba(100, 100, 100, 0.15);
		cursor: pointer;
	}
	.image-holder-box:hover{
		transition: all 0.3s;
		box-shadow: 0px 7px 20px rgba(63, 63, 68, 0.08);
	}
	.image-holder-box-2{
		display: table;
		width: 100%;
		height: 200px;
		text-align: center;
		vertical-align: top;
		border: 1.5px dashed #d8d7d7;
		background-color: #ECE9F1;
		box-shadow: 0px 1px 3px rgba(100, 100, 100, 0.15);
		cursor: pointer;
	}
	.image-holder-box-2:hover{
		transition: all 0.3s;
		box-shadow: 0px 7px 20px rgba(63, 63, 68, 0.08);
	}
	.reducediv {
		margin-bottom: 0;
		height: 30px;
	}
	.edit-delete {
		cursor: pointer;
		color: rgba(0, 0, 0, 0.65);
		transition:color .1s ease-in;
	}
	.edit-delete:hover {
		color: orange;
		transition:color .1s ease-in;
	}

</style>
