<template>
    <a-drawer
        :wrapClassName="'drawer-style'"
        :visible="showMarketplace"
        :width="'calc(100% - 226px)'"
        :zIndex="1000"
        :title="'Bildhive Workplace'"
        @close="onClose"
        :headerStyle="{
            'background-color': `${topbarColor}`,
            'border-radius': '0px',
        }"
        :bodyStyle="{ padding: '0px' }"
    >
        <div>
            <div style="padding: 10px 24px; border-bottom: 1px solid #edeff2">
                <div
                    style="cursor: pointer; display: inline-block"
                    @click="$store.commit('CLOSE_MARKETPLACE', {})"
                >
                    <div class="dF">
                        <div class="mr-3"><a-icon type="left" /></div>
                        <div>Bildhive Apps</div>
                    </div>
                </div>
            </div>
            <div style="padding: 24px 70px 0 70px">
                <div
                    style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    "
                >
                    <div class="dF aC">
                        <div class="mr-3">
                            <img :src="getAppIcon(chosenApp.media)" />
                        </div>
                        <div>
                            <h3>
                                {{
                                    chosenApp && chosenApp.name
                                        ? chosenApp.name
                                        : ""
                                }}
                            </h3>
                            <div>
                                {{
                                    chosenApp &&
                                    chosenApp.media &&
                                    chosenApp.media.category
                                        ? chosenApp.media.category
                                        : ""
                                }}
                            </div>
                        </div>
                    </div>
                    <div>
                        <a
                            :href="
                                chosenApp && chosenApp.subdomain
                                    ? `https://${chosenApp.subdomain}.bildhive.${tld}`
                                    : ''
                            "
                        >
                            <a-button
                                class="dF"
                                style="align-items: center"
                                type="primary"
                            >
                                <div class="pr-3">LAUNCH APP</div>
                                <div class="dF" style="align-items: center">
                                    <svg
                                        style="height: 12px; width: 12px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#fff"
                                        width="16.542"
                                        height="16.542"
                                        viewBox="0 0 16.542 16.542"
                                    >
                                        <path
                                            id="Icon_material-launch"
                                            data-name="Icon material-launch"
                                            class="cls-1"
                                            d="M19.2,19.2H6.338V6.338h6.433V4.5H6.338A1.837,1.837,0,0,0,4.5,6.338V19.2a1.837,1.837,0,0,0,1.838,1.838H19.2A1.843,1.843,0,0,0,21.042,19.2V12.771H19.2ZM14.609,4.5V6.338h3.3L8.874,15.372l1.3,1.3L19.2,7.634v3.3h1.838V4.5Z"
                                            transform="translate(-4.5 -4.5)"
                                        />
                                    </svg>
                                </div>
                            </a-button>
                        </a>
                    </div>
                </div>
                <div
                    class="mt-5 workplace-drawer w-full"
                    style="justify-content: space-between"
                >
                    <!-- <div class="workplace-image">
                        <div
                            style="
                                position: relative;
                                background-color: #e4e4e4;
                                width: 100%;
                                height: 400px;
                            "
                        >
                            <div
                                class="dF aC"
                                style="
                                    position: absolute;
                                    height: 100%;
                                    width: 100%;
                                "
                            >
                                <div
                                    class="dF w-full"
                                    style="justify-content: space-between"
                                >
                                    <div class="move-button">
                                        <a-icon type="left" />
                                    </div>
                                    <div class="move-button">
                                        <a-icon type="right" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dF mt-3">
                            <div
                                class="mr-3"
                                style="
                                    width: 100px;
                                    height: 50px;
                                    background-color: #e4e4e4;
                                "
                            ></div>
                            <div
                                class="mr-3"
                                style="
                                    width: 100px;
                                    height: 50px;
                                    background-color: #e4e4e4;
                                "
                            ></div>
                            <div
                                class="mr-3"
                                style="
                                    width: 100px;
                                    height: 50px;
                                    background-color: #e4e4e4;
                                "
                            ></div>
                            <div
                                style="
                                    width: 100px;
                                    height: 50px;
                                    background-color: #e4e4e4;
                                "
                            ></div>
                        </div>
                    </div> -->
					<appDetails class="workplace-image" />

                    <div class="workplace-description">
                        <div>
                            <div class="dF aC">
                                <p class="mr-4">Version</p>
                                <p style="color: #9fa2a5">
                                    1.3 | Released 6/5/2020
                                </p>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <div class="dF aC">
                                <p class="mr-4">For Support Request</p>
                                <p style="color: #9fa2a5">
                                    {{ supportRequestType }}
                                </p>
                            </div>
                            <a style="color: var(--orange); margin: 0px" href="https://support.bildhive.com/en/support/tickets/new" target="_blank"
                                >Submit a support request</a
                            >
                        </div>
                        <hr />
                        <div>
                            <p>Resource</p>
                            <a style="color: var(--orange)" :href="articleLink" target="_blank"
                                >See how this app works</a
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="dependencies.length != 0"
                class="mt-5"
                style="background-color: #faf9f9; padding: 24px 70px 24px 70px"
            >
                <h4>Related Apps</h4>
                <div class="mt-3 dF">
                    <div
                        v-for="(app, appI) in dependencies"
                        :key="appI"
                        class="mr-3 dF aC"
                        style="
                            width: 240px;
                            height: 190px;
                            background-color: #fff;
                            padding: 10px;
                        "
                    >
                        <div class="w-full dF" style="justify-content: center">
                            <div style="text-align: center">
                                <img
                                    style="margin: auto"
                                    :src="getAppIcon(app.media)"
                                />
                                <h5 class="mt-3">{{ app.name }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a-drawer>
</template>


<script>
import appDetails from "./appdetails.vue";

export default {
    components: { appDetails },

    data: function () {
        return {
            tld:
                window.location.href.includes("localhost") ||
                window.location.href.includes(".dev")
                    ? "dev"
                    : "com",
        };
    },
    computed: {
		articleLink(){
			return this.chosenApp && this.chosenApp.articles && this.chosenApp.articles[0] || '#'
		},

        supportRequestType() {
			const packageExtras = this.instance.selectedPackageExtras || []
			const videoChatSupport = packageExtras.find(p => p && p.package_extra && (p.package_extra.identifier === 'online_video_chat_support'))

			if (videoChatSupport) {
				return "Ticket & Video Chat Support";
			} else {
				return "Ticket Support";
			}
		},

        topbarColor() {
            let color = "";
            if (
                this.chosenApp &&
                this.chosenApp.media &&
                this.chosenApp.media.category
            ) {
                if (
                    this.chosenApp.media.category ==
                    "Administration & Team Management"
                ) {
                    color = "#F7941E";
                } else if (
                    [
                        "Project Setup & Configuration",
                        "Project Configuration",
                        "Product Management",
                    ].includes(this.chosenApp.media.category)
                ) {
                    color = "#9693E7";
                } else if (
                    this.chosenApp.media.category ==
                    "Marketing & Communications Management"
                ) {
                    color = "#2C94C8";
                } else if (
                    this.chosenApp.media.category ==
                    "Customer & Transaction Management"
                ) {
                    color = "#00C48C";
                }
            }
            return color;
        },
        dependencies() {
            let apps = [];
            if (this.chosenApp && this.chosenApp.dependencies) {
                this.chosenApp.dependencies.forEach((dep) => {
                    let found = this.apps.find((app) => app.id == dep);
                    if (found) {
                        apps.push(found);
                    }
                });
            }
            return apps;
        },
        chosenApp() {
            return this.$store.state.marketPlace.item;
        },
        instance() {
            return this.$store.state.instance;
        },
        showMarketplace() {
            return this.$store.state.marketPlace.show;
        },
        apps() {
            return this.$store.state.instance.apps;
        },
    },
    methods: {
        getAppIcon(media) {
            let icon = "";
            if (media && media.icons) {
                icon = media.icons.svg || media.icons.png;
            }
            return icon;
        },
        onClose() {
            this.$store.commit("CLOSE_MARKETPLACE", {});
        },
    },
};
</script>

<style scoped>
.move-button {
    background-color: #f1f1f1;
    padding: 5px;
}
.move-button:hover {
    background-color: #fff;
}
@media screen and (max-width: 1030px) {
    .workplace-drawer {
        display: block;
    }
    .workplace-image {
        width: 100%;
        margin-right: 0px;
    }
    .workplace-description {
        margin-top: 45px;
        margin-left: 0px;
    }
}
@media screen and (min-width: 1030px) {
    .workplace-drawer {
        display: flex;
    }
    .workplace-image {
        width: 60%;
        max-width: 750px;
        margin-right: 20px;
    }
    .workplace-description {
        width: 40%;
        margin-left: 20px;
    }
}
.app-holder-box {
    display: table;
    width: 100%;
    height: 200px;
    text-align: center;
    vertical-align: top;
    border: 1px solid #d8d7d7;
    border-color: transparent;
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(100, 100, 100, 0.15);
}
.free-button {
    background-color: #9693e7;
    border-color: #9693e7;
}
.free-button:hover {
    background-color: #aaa7f2;
    border-color: #aaa7f2;
    transition: ease-in 0.2s;
}
.faq-title {
    font-weight: bold;
    cursor: pointer;
    user-select: none;
}
.faq-content {
    color: #707070;
    user-select: none;
}
</style>

<style>
.drawer-style .ant-drawer-title {
    color: white;
}
/* .drawer-style .ant-drawer-wrapper-body {
		background-color: #FAF9F9;
	}
	.drawer-style .ant-drawer-header {
		background-color: #9693E7;
		border-radius: 0;
	}
	.drawer-style .ant-drawer-header .ant-drawer-title {
		color: white;
	} */
.drawer-style .ant-drawer-close {
    color: white;
}

.tab-container {
    background-color: white;
}

.tab-container > .ant-tabs-card > .ant-tabs-content {
    margin-top: 16px;
}

.tab-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px 0;
}

.tab-container > .ant-tabs-card > .ant-tabs-bar {
    border-color: #fff;
    margin-top: 20px;
    border-top: 1px solid #edeff2;
    border-bottom: 1px solid #edeff2;
}

.tab-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
}

.tab-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff;
    color: rgba(0, 0, 0, 0.65);
    background: #ece9f1;
    border-radius: 0;
}

.tab-container .ant-tabs-nav {
    margin-left: 15%;
}

.tab-container .ant-tabs-nav .ant-tabs-tab {
    margin-right: 0 !important;
}
</style>
