import { render, staticRenderFns } from "./appcards.vue?vue&type=template&id=ff6f493c&scoped=true"
import script from "./appcards.vue?vue&type=script&lang=js"
export * from "./appcards.vue?vue&type=script&lang=js"
import style1 from "./appcards.vue?vue&type=style&index=1&id=ff6f493c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff6f493c",
  null
  
)

export default component.exports