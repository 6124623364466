<template>
	<div>
		<div>
			<h4>Description</h4>
			<div style="text-align: justify">
				<span v-html="appInfo" />
			</div>
		</div>
		<div class="mt-5" v-if="appKeyFeatures.length">
			<h5>Key Features</h5>
			<ul>
				<li v-for="(item, index) in appKeyFeatures" :key="index" class="my-3" style="text-align: justify">
					<span v-html="item" />
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		openedApp() {
			return this.$store.state.marketPlace.item.name;
		},

		instance() {
			return this.$store.state.instance;
		},

		appInfo() {
			let info = "";
			switch (this.openedApp) {
				case "Team Configurator":
					info =
						"Team Configurator allows the system owner to set up and establish the users that will be assigned to the development project. This allows the system owner to assign user permissions to the various people on the team, based on their user requirement and/or profiles. Thus, giving the system owner complete control.";
					break;
				case "Reports & Analytics":
					info =
						"The Reports & Analytics application centralizes all the platform reports in one cohesive application, allowing its selected users to monitor, review, measure and analyze the performance of various activities within the Bildhive platform. This information gives its users insights into what’s working and what isn’t which helps in both short and long term planning and decision making.";
					break;
				case "Amenity Map Configurator":
					info =
						"The Amenity Map Configurator allows users to develop amenity maps with ease by allowing them to select categories and ratings from community preferences that have been entered into Google. Users can then go in and add, remove or customize specific points of interest that help promote their projects.";
					break;
				case "Content Asset Management":
					info =
						"The Content Asset Management System allows users to store and manage their digital assets, that are used within the Bildhive platform. This allows users to store, retrieve, share, and publish digital files (i.e. images, videos, documents, renderings, floorplans, animations etc.).";
					break;
				case "Condo Unit Configurator":
					info =
						"The Condo Unit Configurator allows users to prepare and create all available units, personalized to users search criteria including number of bedrooms, bathrooms, size, budget etc.. Users can centrally manage unit assets through Bildhive’s CAM (interior renderings, floor plans, options, unit description detail, pricing, downloadable brochures & features & finishes).";
					break;
				case "Condo Grid Configurator":
					info =
						"The Condo Grid Configurator allows users to digitally prepare, create and establish a predefined condo grid by floor and unit type. This application acts a further layer to the CUC, as it allows the user to configure and showcase the units interactively.";
					break;
				case "Form & Survey Configurator":
					info =
						"Bildhive’s Form & Survey Configurator allows users to create and generate various forms (web, landing page, sales center registration forms, rental application forms etc. in order to support real estate transactions. <br><br> This application integrates seamlessly with Bildhive CRM, allowing users to easily collect, tag, and score prospect interactions throughout the marketing and sales process."
					if (this.instance.package && this.instance.package.toLowerCase().includes('premium')) {
						info += "<br><br> User can configure worksheets so sales representative can submit units, unit add ons or lots. <br><br> The worksheet integrates seamlessly with worksheet management and transaction management."
					}
					break;
				case "Contract Configurator":
					info =
						"Bildhive’s Contract Configurator allows users to configure Agreements of Purchase and Sale, Rental Agreements, Amendments, Mutual Releases, Reservation Agreements, Co-op Forms, and other Supporting Documents needed to support transactions, so users can process transactions digitally and at scale.";
					break;
				case "Features Configurator":
					info =
						"The Features Configurator allows users to upload exterior features and colour packages, or features and finishes for interiors. (I.e. Electrical, Flooring, Kitchen etc.). This information can be communicated on web, mobile, tablet or on sales presentation centre touchscreens.";
					break;
				case "Home Model Configurator":
					info =
						"Allows users to configure all available plans/models, personalized to users search criteria including number of bedrooms, bathrooms, size, budget etc.";
					break;
				case "Site Plan Configurator":
					info =
						"The Site Plan Configurator allows users to digitally prepare, create and apply architectural controls to the home building lots within the community site plan.";
					break;
				case "Master Plan Configurator":
					info =
						"The Master Plan Configurator is used in those situations when there are multiple builders involved in a group community project. <br/>The information shown in the MPC is integrated with the Community Site Plan of each builder, to provide a global perspective of the community to prospects.";
					break;
				case "Website & Landing Page Builder":
					info =
						"Allows users to create custom corporate websites as well as community based websites, and/or microsites without having to program. The Website Builder leverages dynamic widgets that are tailored to support today’s real estate market place. It integrates seamlessly with other applications within the Bildhive platform. (CAM, AMC, FSC, SPC, HMC, CGC, Reports & Analytics, and payment gateway). <br/><br/>For users with programming experience, the Bildhive platform offers an API that will connect their custom websites and landing pages to Bildhive. <br/><br/>Bildhive’s Landing Page Builder allows users to design and create multiple landing pages so that users can easily segment their target customers. This application gives users the power to optimize their entire digital marketing strategy to help drive traffic, improve SEO while encouraging customers to take action. WLPB is integrated with Bildhive’s FSC, CAM, CRM, Broadcast Email Builder, Reports & Analytics making it seamless and easy to use.";
					break;
				case "Broadcast Email Builder":
					info =
						"Bildhive’s easy to use Broadcast Email Builder allows users to design, create and deploy beautifully branded responsive emails that engage with potential and existing prospects without the need to program.";
					break;
				case "Touchscreen Builder":
					info =
						"Allows users to design, create and publish touchscreen presentations using dynamic widgets that will engage, inform and motivate purchase at point of sale. Stream the latest interior and exterior renderings, videos, 3D animations, models, pricing, features and finishes, interactive amenity maps, and site plans. Virtually any asset that you can imagine can be viewed seamlessly, and in real time, to provide an immersive presentation. TB is integrated with Bildhive’s FSC, CAM, CRM, HMC, SPC, CGC, and FC.";
					break;
				case "Publishing Manager":
					info =
						"Bildhive’s PM allows users to connect their domain to their website and landing pages. Users also use this application to connect Bildhive to and communicate with hardware (i.e. touchscreens & digital site plan tables) that are typically used in real estate presentation centres.";
					break;
				case "Customer Relationship Management":
					info =
						"The Bildhive CRM is an easy to use and scalable application that helps users store and manage prospect and customer information. Track prospect interactions, preferences, and other pertinent information that will allow sales to provide a flawless and unique customer experience that will inevitably bring prospects closer to a home buying decision.";
					break;
				case "Transaction Management":
					info =
						"Bildhive’s Transaction Management allows users to digitally prepare, finalize, sign, act, manage store and Lease, Rental, Reservation, and Agreements of Purchase and Sale within minutes and at scale,making it highly efficient and far more cost effective than traditional processes.";
					break;
				case "Inventory & Price Management":
					info =
						"This application allows builders and their internal or external sales teams to manage product inventory and pricing for all project types.";
					if (this.instance.package && this.instance.package.toLowerCase().includes('premium')) {
						info += "<br><br> User can manage and allocate units and unit add ons such as parking spots, lockers and bike racks. <br><br> For Lowrise projects, this application will give builders and their sales teams the ability to allocate lots."
					}
					break;
				case "Worksheet Management":
					info =
						this.$userType == 'agent' ? "Once a real estate sales representative is invited and accepts access to a project, they automatically get access to the worksheet application where they can easily submit their prospective clients’ product choices. (Units, Add Ons, Lots, Model & Elevation)" : "The worksheet management application allows builders and their internal sales teams to easily manage worksheets. Once a worksheet is submitted, it automatically populates the worksheet management application so the builder or internal agent can review to approve, decline or edit the worksheet submission.";
					break;
			}

			return info;
		},

		appKeyFeatures() {
			let features = [];

			switch (this.openedApp) {
				case "Team Configurator":
					features = [
						"Allows owner to establish custom roles and user rights within those roles.",
						"Users can be added, removed or user rights can be edited in real time.",
					];
					break;
				case "Reports & Analytics":
					features = [
						"Sales Reporting",
						"Inventory Reporting",
						"Activity Reporting",
						"Lead Reporting",
						"Email Marketing Campaign Reporting",
						"Website Reporting",
						"Survey Reporting"
					];
					break;
				case "Amenity Map Configurator":
					features = [
						"Saves time as the Amenity Map Configurator pulls data directly from Google.",
						"Allows users to add permanent community or model home markers which allows viewers to visualize distances between amenities and the location of the project.",
						"Application allows users to use the pre-defined icons or the ability to import custom icons.",
					];
					break;
				case "Content Asset Management":
					features = [
						"All assets are contained in the Bildhive platform, reducing the need to store assets in different systems.",
						"Allows users to create a filing management system to help organize their assets.",
						"Assets are sharable between team members.",
						"Assets can be applied across all the Bildhive platform apps. AMC, HMC, SPC, CGC, CUC, FSC.",
					];
					break;
				case "Condo Unit Configurator":
					features = [
						"The CUC is integrated with the CAM which feeds the data to Web, Mobile, Tablets, Touchscreens, Digital Signage, Contract addendums, virtually anything that helps in the sales and marketing of the condo units.",
						"The CUC syncs with Bildhive’s Condo Grid Configurator to provide real time availability data of all condo units by floor and unit type.",
						"This application syncs with our online reservation and payment gateway where purchasers can select, reserve and pay online.",
					];
					break;
				case "Condo Grid Configurator":
					features = [
						"The CGC is integrated with the CAM which feeds the data to Web, Mobile, Tablets, Touchscreens, Digital Signage, Contract addendum’s, virtually anything that helps in the sales and marketing of the condo units.",
						"The CGC provides real time availability data using the predefined condo grid by floor and unit type.",
						"This application syncs with our online reservation and payment gateway where purchasers can select, reserve and pay online.",
					];
					break;
				case "Form & Survey Configurator":
					features = [
						"The application includes several form creation features which gives users the ability to customize their forms (i.e. text, number, drop down, radio button, check box, date/time, file attachment, tags fields, etc.)",
						"Automatically integrates with Bildhive Website & Landing Page Builder which allows users to easily collect prospect information directly into CRM for ongoing communications and nurturing.",
						"Includes a submission notification feature. When a registrant fills in the form, Bildhive sends an email notification to the users."
					];
					if (this.instance.package && this.instance.package.toLowerCase().includes('premium')) {
						features.push("Can create a default worksheet or custom worksheets.")
					}
					break;
				case "Contract Configurator":
					features = [
						"The application includes several form creation features which gives users the ability to customize their forms (i.e. text, number, drop down, radio button, check box, date/time, file attachment, tags fields, etc.)",
						"Automatically integrates with Bildhive Website & Landing Page Builder which allows users to easily collect prospect information directly into CRM for ongoing communications and nurturing",
						"Includes a submission notification feature. When a registrant fills in the form, Bildhive sends an email notification to the users"
					];
					break;
				case "Features Configurator":
					features = [
						"The FC is integrated with the CAM which feeds the data to Web, Mobile, Tablets, Touchscreens.",
					];
					break;
				case "Home Model Configurator":
					features = [
						"Allows users to centrally manage their home model assets through Bildhive’s Content Asset Management system (renderings, floor plans, options, model home description detail, pricing, downloadable brochures & features & finishes).",
						"CAM feeds the home model information to various mediums - Web, Mobile, Touchscreen, Digital Signage, Contracts, virtually anything that helps in the sales and marketing of the homes.",
						"The Home Model Configurator is synced with the site plan application to provide real time home model availability status.",
						"This application syncs with our online reservation and payment gateway where purchasers can select, reserve and pay online.",
					];
					break;
				case "Site Plan Configurator":
					features = [
						"The SPC is integrated with the CAM which feeds the data to Web, Mobile, Tablets, Touchscreens, Digital Signage, Contract addendum’s, virtually anything that helps in the sales and marketing of the community and the homes.",
						"SPC is synced with the HMC application to provide real time home building lot availability status.",
						"This application syncs with our online reservation and payment gateway where purchasers can select, reserve and pay online.",
					];
					break;
				case "Master Plan Configurator":
					features = [
						"The MPC is integrated with the CAM which feeds the data to Web, Mobile, Tablets, Touchscreens, Digital Signage, Contract addendum’s, virtually anything that helps in the sales and marketing of the community and the homes.",
						"MPC is synced with the SPC application to provide real time home building lot availability status.",
						"This application syncs with our online reservation and payment gateway where purchasers can select, reserve and pay online.",
					];
					break;
				case "Website & Landing Page Builder":
					features = [];
					break;
				case "Broadcast Email Builder":
					features = [
						"Automates and segments communications by recipient variables to achieve a more personalized and targeted experience.",
						"A/B testing to help discover what resonates with your target audience.",
						"Email deliverability which verifies each and every email address to ensure that it’s authentic to protect sender reputation.",
						"Reports & Analytics to help determine which email marketing campaigns are working. See opens, clicks, performance by mailbox providers, device types, and more.",
					];
					break;
				case "Touchscreen Builder":
					features = [];
					break;
				case "Publishing Manager":
					features = [];
					break;
				case "Customer Relationship Management":
					features = [
						"Contact Management - Allows users to collect prospect data — everything from contact details, interactions with email communications to the number of sales centre visits and home buying conversations are easily available to access and update and once a prospect buys, the Sales agreements and all other pertinent buyer information is stored in the contact/buyer record.",
						"Lead Management - Enables users to track the lead from web all the way through to sellout and close by leveraging the form builder application toolset.",
						"Lead Notification - The application notifies the designated sales agent or broker when a new lead has registered (i.e. via an email notification).",
						"Lead Scoring and Forecasting - The application allows Customer Relationship Management (CRM) user to create settings where they can establish their own parameters. This gives sales agents and brokers better visibility over their pipelines and allows them to qualify leads more accurately throughout each stage in the home buying lifecycle.",
						"Integrates with Form/Registration Builder, Email Builder, Deal & Contract Repository.",
						"Tasks and Appointments - Allows Sales people to set and manage tasks and to do’s, such as reminders, manage appointments, establish follow up dates, 1 to 1 emails, phone calls, meetings, etc.",
					];
					break;
				case "Transaction Management":
					features = [
						"Allows users to select the appropriate contract template",
						"Integrates with DocuSign, executed agreements are automatically filed and linked to CRM along with a legally binding and admissible certificate of completion",
						"Users can integrate their existing DocuSign for Real Estate SKU License",
						"Visibility into where the contract stands at each stage within the execution process",
						"Highly secure solution meets the most stringent global security standards",
						"Bildhive integration with DocuSign generates agreements that are lawful and admissible in a court of law",
						"Can add purchaser details by scanning license or passport using a tablet",
						"Additional support documents such as, Amendments, Mutual Releases, Fintrac, Co-op forms etc. can be easily prepared and added to support the transaction",
						"Allows for easy configuration of deposit structures and inclusions"
					];
					break;
				case "Inventory & Price Management":
					features = [
						"Integrates with Transaction Management and Bildhvie’s omni channel publishing management system which provides inventory availability status in real-time across web, mobile, and all sales center devices",
						"Segments inventory status (available, sold, hold, conditional, inventory, not released etc.)",
						"Users can control product releases",
						"Users can also control and modify pricing to units, models, lots, premiums etc. by dollar or percentage",
						"Users also have access to price history activity"
					];
					if (this.instance.package && this.instance.package.toLowerCase().includes('premium')) {
						features.push(...[
							"Users can establish Agent Commission Structures & fees as well as Agent Payout Structures",
							"Users can choose to apply a reservation fee when reserving a unit, unit add on or lot",
							"Reservation fees are automatically funded into your account through Bildhive’s payment gateway in various currencies",
							"Users can choose to refund all or part of the reservation fee should a prospect choose to withdraw",
							"Users can review allocation and commission history"
						])
					}
					break;
				case "Worksheet Management":
					features = this.$userType == 'agent' ? [
						"No need to handwrite, scan and send the worksheet",
						"Automatically submits to Builder to review, approve or decline",
						"Instant notification when the worksheet is approved or declined",
						"Real time status updates on all submitted worksheets"
					] : [
						"Takes seconds to review, approve or decline a worksheet",
						"Co-operating agent is automatically notified when the worksheet is approved",
						"Builders can send out a reservation agreement with each worksheet",
						"Builders can directly collect reservation fees using Bildhive’s payment gateway."
					];
					break;
			}

			return features;
		},
	},
};
</script>

<style lang="scss" scoped>
</style>
